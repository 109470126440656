@media (width <= 1199.98px) {
  .header {
    justify-content: space-between;
    display: flex;
  }
}

@media (width >= 1200px) {
  .header {
    gap: var(--glob-gap);
    grid-template: 1fr / 1fr 1fr 1fr 1fr;
    display: grid;
  }
}

@media (width >= 992px) {
  .header {
    padding: 0 var(--horizontal-offset);
  }
}

@media (width <= 991.98px) {
  .header .displace, .header .link {
    line-height: unset;
    height: auto;
  }
}

@media (width >= 1200px) and (any-pointer: fine) {
  progress {
    left: 5px;
  }
}

@media (width >= 1200px) and (any-pointer: fine) {
  progress::-webkit-progress-value {
    background-color: var(--c-cinnabar);
  }
}

@media (width >= 992px) {
  .footer-commons[data-v-5749a0] {
    grid-template: 1fr 1fr / calc(50% - var(--glob-gap) / 2) 1fr 1fr;
    gap: calc(var(--glob-gap) * 2) var(--glob-gap);
    margin-bottom: calc(var(--vertical-offset) * -.75);
    padding-top: calc(var(--vertical-offset) / 3);
  }
}

@media (width <= 991.98px) {
  .footer-commons .footer-slogan[data-v-5749a0] {
    padding-bottom: var(--glob-gap);
    box-sizing: border-box;
    box-shadow: 0 -1px 0 0 inset var(--border-shadow-color);
    align-self: start;
  }
}

@media (width >= 992px) {
  .footer-commons .footer-slogan[data-v-5749a0] {
    grid-area: 1 / 1 / 3 / 2;
  }
}

@media (width >= 992px) {
  .footer-commons .common--menu[data-v-5749a0] {
    grid-area: 1 / 2 / 2 / 3;
  }
}

@media (width >= 992px) {
  .footer-commons .common--connect[data-v-5749a0] {
    grid-area: 1 / 3 / 2 / 4;
  }
}

@media (width >= 992px) {
  .footer-commons .common--status[data-v-5749a0] {
    grid-area: 2 / 2 / 3 / 3;
  }
}

@media (width >= 992px) {
  .footer-commons .common--copy[data-v-5749a0] {
    grid-area: 2 / 3 / 3 / 4;
  }
}

.svg-icon[data-v-95bb2f] {
  vertical-align: top;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.random-guesses[data-v-6903a9] {
  color: var(--c-wet-asphalt);
}

.header {
  padding: 12px var(--horizontal-offset);
  position: relative;
}

@media (width <= 1199.98px) {
  .header {
    justify-content: space-between;
    display: flex;
  }
}

@media (width >= 1200px) {
  .header {
    gap: var(--glob-gap);
    grid-template: 1fr / 1fr 1fr 1fr 1fr;
    display: grid;
  }
}

.header > * {
  align-self: center;
  display: grid;
}

@media (width >= 992px) {
  .header {
    padding: 0 var(--horizontal-offset);
  }
}

.header:after {
  content: "";
  inset: auto 0 0 var(--horizontal-offset);
  box-sizing: border-box;
  box-shadow: 0 -1px 0 0 inset var(--border-shadow-color);
  width: calc(var(--header-border-width, 100%)  - var(--horizontal-offset) * 2);
  transform-origin: 100%;
  height: 1px;
  position: absolute;
}

@media (width <= 991.98px) {
  .header .displace, .header .link {
    line-height: unset;
    height: auto;
  }
}

.app-transition[data-v-fbb128] {
  pointer-events: none;
  z-index: var(--z-index-app-transition);
  position: fixed;
  inset: 0;
}

.app-transition[data-v-fbb128]:before {
  pointer-events: none;
  content: "";
  background: var(--c-background-secondary);
  opacity: var(--shutter, 0);
  will-change: opacity;
  display: block;
  position: fixed;
  inset: 0;
}

.app-transition-shutter[data-v-fbb128] {
  pointer-events: auto;
  background-color: var(--c-background-primary);
  transform-origin: bottom;
  will-change: transform;
  width: 100%;
  height: 102%;
  transform: translateY(102%);
}

.cursor[data-v-e3d3a7] {
  --size-blob: 50px;
  --size-border: 2px;
  --color: white;
  --background: transparent;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  mix-blend-mode: exclusion;
  z-index: var(--z-index-cursor-blob);
  opacity: 0;
  transition: opacity 2s .5s;
  position: fixed;
}

.cursor__rim[data-v-e3d3a7] {
  will-change: transform;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: var(--size-blob);
  height: var(--size-blob);
  position: fixed;
  inset: 0;
}

.cursor__rim[data-v-e3d3a7]:after {
  content: "";
  box-shadow: 0 0 0 var(--size-border) inset var(--color);
  background-color: var(--background);
  transform: scale(var(--scale, 1));
  filter: blur(var(--blur, 0));
  border-radius: 100%;
  transition: box-shadow .325s, transform .325s, background-color .325s;
  position: absolute;
  inset: 0;
}

.cursor__dot[data-v-e3d3a7] {
  will-change: transform;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: var(--z-index-cursor-blob);
  width: var(--size-blob);
  height: var(--size-blob);
  place-content: center;
  display: grid;
  position: fixed;
  inset: 0;
}

.cursor__dot[data-v-e3d3a7]:after {
  content: "";
  color: #0000;
  background-color: var(--color);
  border-radius: 100%;
  width: 6px;
  height: 6px;
  transition: box-shadow .325s, transform .325s, color .325s;
  display: block;
}

body.page-loaded .cursor[data-v-e3d3a7] {
  opacity: 1;
}

.cursor--headline-large[data-v-e3d3a7] {
  --color: transparent;
  --background: white;
  --scale: 3;
}

.cursor--flow[data-v-e3d3a7] {
  --color: transparent;
  --background: black;
  --scale: 3;
  mix-blend-mode: unset;
}

.cursor--flow .cursor__rim[data-v-e3d3a7]:after {
  box-shadow: 0 0 0 .5px #fff;
}

.cursor--flow .cursor__dot[data-v-e3d3a7]:after {
  content: "Подробнее";
  white-space: nowrap;
  color: #fff;
  width: 100%;
  height: 100%;
  margin: auto;
  font-size: 1.4em;
  font-weight: 500;
  inset: 0;
}

.cursor--aside[data-v-e3d3a7] {
  --color: transparent;
  --background: white;
}

.cursor--image-one[data-v-e3d3a7] {
  --color: transparent;
  --background: white;
  --scale: 2;
  --blur: 7px;
}

.cursor--contact-form[data-v-e3d3a7] {
  --color: transparent;
  --background: white;
  --scale: .2;
}

.cursor--none[data-v-e3d3a7] {
  --color: transparent;
  --background: transparent;
}

.image-container[data-v-ffdeb7] {
  width: 100%;
  height: 100%;
}

.image-container picture[data-v-ffdeb7] {
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
}

.image-container img[data-v-ffdeb7] {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.image-container[data-parallax][data-v-ffdeb7] {
  position: relative;
  overflow: hidden;
}

.aside-item {
  pointer-events: auto;
  width: calc(var(--aside-width)  - 20px);
  -webkit-user-select: none;
  user-select: none;
  min-height: 300px;
  padding: 20px 10px 20px 20px;
  position: absolute;
  top: 0;
  right: 20px;
}

.aside-item .image-container {
  border-radius: .5px;
  overflow: hidden;
}

.aside-item .display-small {
  color: var(--c-text-tertiary);
  margin: 0;
  padding: 20px 0 20px .3em;
  font-size: 2.8em;
  font-weight: 800;
}

.aside[data-v-6b37bd] {
  opacity: 0;
  pointer-events: none;
  width: 100vw;
  margin: auto;
  transition: opacity .75s ease-in;
  position: fixed;
  inset: 0 auto 0 0;
}

body.page-loaded .aside[data-v-6b37bd] {
  opacity: 1;
}

.aside .vertical-loop[data-v-6b37bd] {
  pointer-events: none;
  width: var(--aside-width);
  background-color: var(--c-background-secondary);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.stripes {
  opacity: 0;
  pointer-events: none;
  inset: 0 var(--horizontal-offset);
  z-index: 1;
  transition: opacity .75s ease-in;
  position: absolute;
}

body.page-loaded .stripes {
  opacity: 1;
}

.stripes:after {
  content: "";
  z-index: 1;
  width: var(--stripes-width);
  min-height: calc(100vh + var(--header-height));
  background: repeating-linear-gradient(90deg, var(--stripes-c-thread), var(--stripes-c-thread) 1px, transparent 1px, transparent 12.5%) 0 0, repeating-linear-gradient(90deg, var(--stripes-c-thread), var(--stripes-c-thread) 1px, transparent 1px, transparent 12.5%) calc(var(--glob-gap) * -1) 0, repeating-linear-gradient(90deg, transparent 0, transparent var(--glob-gap), var(--stripes-c-wide) 0, var(--stripes-c-wide) 12.5%) calc(var(--glob-gap) * -1) 0;
  position: absolute;
  inset: 0;
}

progress {
  z-index: var(--z-index-indicator);
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  width: 100vw;
  height: 5px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: clip;
}

@media (width >= 1200px) and (any-pointer: fine) {
  progress {
    left: 5px;
  }
}

progress::-webkit-progress-bar {
  background: none;
}

progress::-webkit-progress-value {
  background-color: var(--c-indian-red);
}

@media (width >= 1200px) and (any-pointer: fine) {
  progress::-webkit-progress-value {
    background-color: var(--c-cinnabar);
  }
}

.widget-heading[data-v-586f9a] {
  margin: 0 var(--horizontal-offset) calc(var(--vertical-offset) * 1.5);
  opacity: 0;
  transition: opacity .75s ease-in;
  position: relative;
}

body.page-loaded .widget-heading[data-v-586f9a] {
  opacity: 1;
}

.widget-heading__body[data-v-586f9a] {
  margin-top: calc(var(--vertical-offset) / 3);
  padding-top: var(--glob-gap);
  box-sizing: border-box;
  box-shadow: inset 0 0 0 2px red;
  box-shadow: 0 -1px 0 0 var(--border-shadow-color);
}

.widget-heading h3.display-medium[data-v-586f9a] {
  margin-top: 0;
  margin-bottom: 0;
}

.footer-commons[data-v-5749a0] {
  gap: calc(var(--glob-gap) * 1.5) var(--glob-gap);
  grid-template: auto / 1fr 1fr;
  display: grid;
}

@media (width >= 992px) {
  .footer-commons[data-v-5749a0] {
    grid-template: 1fr 1fr / calc(50% - var(--glob-gap) / 2) 1fr 1fr;
    gap: calc(var(--glob-gap) * 2) var(--glob-gap);
    margin-bottom: calc(var(--vertical-offset) * -.75);
    padding-top: calc(var(--vertical-offset) / 3);
  }
}

.footer-commons .footer-slogan[data-v-5749a0] {
  grid-area: 1 / 1 / 2 / 3;
}

@media (width <= 991.98px) {
  .footer-commons .footer-slogan[data-v-5749a0] {
    padding-bottom: var(--glob-gap);
    box-sizing: border-box;
    box-shadow: 0 -1px 0 0 inset var(--border-shadow-color);
    align-self: start;
  }
}

@media (width >= 992px) {
  .footer-commons .footer-slogan[data-v-5749a0] {
    grid-area: 1 / 1 / 3 / 2;
  }
}

.footer-commons .footer-slogan .title-large[data-v-5749a0] {
  text-transform: none;
  max-width: 330px;
  margin: 0;
  line-height: 1.5;
}

.footer-commons .common--menu[data-v-5749a0] {
  grid-area: 2 / 2 / 3 / 3;
}

@media (width >= 992px) {
  .footer-commons .common--menu[data-v-5749a0] {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.footer-commons .common--connect[data-v-5749a0] {
  grid-area: 3 / 2 / 4 / 3;
}

@media (width >= 992px) {
  .footer-commons .common--connect[data-v-5749a0] {
    grid-area: 1 / 3 / 2 / 4;
  }
}

.footer-commons .common--status[data-v-5749a0] {
  grid-area: 3 / 1 / 4 / 2;
}

@media (width >= 992px) {
  .footer-commons .common--status[data-v-5749a0] {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.footer-commons .common--copy[data-v-5749a0] {
  grid-area: 2 / 1 / 3 / 2;
}

@media (width >= 992px) {
  .footer-commons .common--copy[data-v-5749a0] {
    grid-area: 2 / 3 / 3 / 4;
  }
}
/*# sourceMappingURL=index.a8023a74.css.map */
